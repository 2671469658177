import React from 'react'
import FlyBank from '../../components/portfolio/fly-bank/index'
import SEO from '../../components/seo'

const FlyBankPage = () => (
  <>
    <SEO title="Tabreezi Portfolio" />
    <FlyBank />
  </>
)

export default FlyBankPage
