import React from 'react'
import styled from 'styled-components'
import ComputerPhoneImage from './images/computer-phone'
import MainImage from './images/main-phone'
import WalkThroughImage from './images/walk-through'
import PhonesImage from './images/phones'
import RobotFacingRight from '../images/robot-facing-right'
import RobotFacingLeft from '../images/robot-facing-left'

const text = {
  title: 'FLY Bank_',
  introOne: `Only 8% of millenials trust their banks to reach their financial goals. After discovering a lack of educational resources, ancient technology, and poor user experience,`,
  introTwo: `realized banking is broken...`,
  sectionOne:
    'We worked with FLY Bank to create a whole new banking model & application that would provide financial guidance, precise product recommendations, & an experience that users enjoy using while feeling they finally have the tools to truly reach their financial goals. ',
  sectionTwoTitle: 'Product Innovation & Design',
  sectionTwo:
    'We introduced robo-investing to automate financial growth, budget management to monitor your spending, separate savings accounts for easy money management, and in-app card security to a region ready for innovation.',
  sectionThreeTitle: 'Market Understanding',
  sectionThree:
    'The financial revolution in Europe & the US set the example for how users want to manage their money. We realized how quickly new users entering the financial system are moving away from traditional banking models. We identified the gap, researched the market, and conceptualized a product that would spark a new way to bank.',
}

const Landing = () => {
  return (
    <PageContainer>
      <Background style={{ zIndex: 1 }}>
        <IntroContainer>
          <IntroImageContainer>
            <MainImage />
          </IntroImageContainer>
          <IntroTextContainer>
            <IntroTitle>{text.title}</IntroTitle>
            <IntroDetail>
              {text.introOne}{' '}
              <StyledLink href="http://www.flybanking.com" target="_blank">
                Fly Bank
              </StyledLink>{' '}
              {text.introTwo}
            </IntroDetail>
          </IntroTextContainer>
        </IntroContainer>
      </Background>
      <Background color="white" style={{ zIndex: 3 }}>
        <TestImageRight>
          <RobotImageContainerLeft>
            <RobotFacingLeft />
          </RobotImageContainerLeft>
        </TestImageRight>
        <Section style={{ zIndex: 5 }}>
          <TextContainer>{text.sectionOne}</TextContainer>
          <ImageContainer>
            <ComputerPhoneImage />
          </ImageContainer>
        </Section>
      </Background>
      <Section max="2400px" padding="3% 0px" style={{ margin: '0px' }}>
        <SectionTitle>{text.sectionTwoTitle}</SectionTitle>
        <TextContainer>{text.sectionTwo}</TextContainer>
        <ImageContainerFull>
          <WalkThroughImage />
        </ImageContainerFull>
      </Section>
      <Background color="white">
        <TestImageRight>
          <RobotImageContainerRight>
            <RobotFacingRight />
          </RobotImageContainerRight>
        </TestImageRight>
        <Section style={{ paddingBottom: '0px' }}>
          <SectionTitle>{text.sectionThreeTitle}</SectionTitle>
          <TextContainer>{text.sectionThree}</TextContainer>
          <ImageContainer width="70%">
            <PhonesImage />
          </ImageContainer>
        </Section>
      </Background>
    </PageContainer>
  )
}

export default Landing

const Background = styled.div`
  background-color: ${props => props.color || 'black'};
  width: 100vw;
  position: relative;
`

const StyledLink = styled.text`
  color: #38b2dc;
`

const PageContainer = styled.div`
  text-align: center;
  background: white;
  color: black;
  font-family: 'Helvetica Neue';
  line-height: 1.5;
`
const IntroContainer = styled.div`
  background: black;
  color: white;
  display: flex;
  padding: 20px 0px 0px;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    padding: 20px 0px 0px;
  }
`
const IntroImageContainer = styled.div`
  width: 42%;
  padding: 0px 0px 0px 0px;
  padding-left: 3%;
  margin: 0px auto;
  position: relative;
  z-index: 1;
  align-self: flex-end;
`

const IntroTitle = styled.div`
  font-size: 30px;
  font-weight: 500;
  @media screen and (min-width: 375px) {
    font-size: calc(36px + (100 - 36) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 100px;
  }
`

const IntroDetail = styled.div`
  font-size: 10px;
  padding-right: 10px;
  @media screen and (min-width: 375px) {
    font-size: calc(12px + (28 - 12) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 28px;
  }
`

const IntroTextContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 3%;
  padding-bottom: 20px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.padding || '3%'};
  max-width: ${props => props.max || '1200px'};
  overflow: ${props => props.overflow || 'visible'};
  margin: 0 auto;
  position: relative;
`
const TextContainer = styled.div`
  font-size: 12px;
  color: #707070;
  width: 75%;
  margin-bottom: 20px;
  font-weight: 300;
  max-width: 800px;
  padding: 2% 0 3%;
  background-color: transparent;
  z-index: 1;

  @media screen and (min-width: 375px) {
    font-size: calc(12px + (32 - 12) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
`
const ImageContainer = styled.div`
  width: ${props => props.width || '85%'};
  margin: 0px auto;
`

const ImageContainerFull = styled.div`
  width: 100%;
  margin: 0px auto;
`

const SectionTitle = styled.div`
  font-weight: bold;
  @media screen and (min-width: 375px) {
    font-size: calc(12px + (32 - 12) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
`

const RobotImageContainerRight = styled.div`
  position: absolute;
  width: 35%;
  top: 30%;
  left: -27%;
`

const RobotImageContainerLeft = styled.div`
  position: absolute;
  width: 35%;
  top: 8%;
  right: -25%;
`

const TestImageRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100vw;
  margin: 0px auto;
  overflow: hidden;
`
